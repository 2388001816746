import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// CSS
import 'styles/Contact.scss'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import GravityForm from 'components/GravityForm'
import { BannerDefault, ButtonAlt } from 'components/Elements'
import CustomLink from 'components/CustomLink'
import ParseContent from 'components/ParseContent';

// Third Party
import _ from 'lodash'

// Images
import romazo from 'img/romazo.jpg'

function Actie() {
  const {
    actie: {
      acf,
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`
  {
    actie: wordpressPage(wordpress_id: {eq: 856}) {
      acf {
       description
       gravity_form_id
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
  `)


  return (
    <Layout>
      <SEO yoast={{ meta: yoast }} />
      <div className="contact">
        {/* <iframe
          title="google-maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7964744668525!2d5.361201116160054!3d52.22891537976011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c640b020e5effd%3A0x5b3970902352207!2sDe%20Kronkels%2020%2C%203752%20LM%20Bunschoten-Spakenburg!5e0!3m2!1snl!2snl!4v1578565700457!5m2!1snl!2snl"
          width="100%"
          height="350"
          frameBorder="0"
          style={{ border: 0}}
          allowFullScreen=""
        /> */}
        <BannerDefault className="color-background-main py-4">
          <h2 className="w-100 text-center font-size-xl font-weight-xl">Actie! Ontvang gratis montage.</h2>
        </BannerDefault>
        <div className="container py-5">
          <div className="row pb-5">
            <div className="col-12 col-lg-6 px-3 pb-3 d-flex flex-wrap justify-content-start">
              <ParseContent content={acf.description} />
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-lg-end">
              {acf && acf.gravity_form_id && (
                <div className="form-wrapper p-3">
                  <GravityForm id={3} />
                </div>
               )}
            </div>
          </div>

        </div>
      </div>
      {/* <Modal /> */}
    </Layout>
  )
}

export default Actie
